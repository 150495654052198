import './vendor/bootstrap-button';
import './vendor/validator';
import './vendor/jquery-modal';
import '../styles/main.scss';
import { isWeChat } from 'check-platform';
jQuery(document).ready(function ($) {
  var $body = $('body');
  if (isWeChat) {
    $body.addClass('rsc-is-wechat');
  } else {
    $body.removeClass('rsc-is-wechat');
  }

  /**
   * 计时器参数
   */
  var InterValObj; //timer变量，控制时间
  var count = 60; //间隔函数，1秒执行
  var curCount; //当前剩余秒数

  /**
   * 设置计时器
   * @constructor
   */
  function wprs_set_timer() {
    if (curCount === 0) {
      window.clearInterval(InterValObj); //停止计时器
      $('#js-get-code, #js-get-login-code, #js-get-email-code').removeAttr('disabled').val('重新发送'); //启用按钮
    } else {
      curCount--;
      $('#js-get-code, #js-get-login-code, #js-get-email-code').attr('disabled', 'true').val(curCount + '秒后重新获取');
    }
  }

  /**
   * 显示错误消息
   *
   * @param $el 目标元素
   * @param $message 消息内容
   * @param $type 消息类型
   */
  function wprs_show_message($el, $message) {
    var $type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var success = 'success';
    if ($type === false) {
      success = 'danger';
    }
    $el.removeClass('rsc-alert rsc-alert--danger rsc-alert--success').addClass('rsc-alert rsc-alert--' + success).fadeIn().html($message);
  }

  /**
   * 显示字段错误消息
   *
   * @param $el
   * @param $message
   * @param $type
   */
  function wprs_show_field_message($el, $message) {
    var $type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var $success = 'success';
    var $parent = $el.parent();
    if ($type === false) {
      $success = 'danger';
    }
    $el.removeClass('rsc-input--danger rsc-input--success').addClass('rsc-input--' + $success);
    $parent.find('.rsc-field__message').remove();
    if ($message.length > 0) {
      $parent.append('<small class="rsc-field__message rsc-color-' + $success + '">' + $message + '</small>').fadeIn();
    }
  }

  /**
   * Ajax 提交表单
   *
   * @param $form
   * @param $url
   * @param $callback
   */
  function wprs_ajax_submit($form, $url) {
    var $callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var $submit = $form.find('input[type=submit], button[type=submit]');
    var $message = $form.find('.js-security-status');
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: $url,
      data: $form.serialize(),
      beforeSend: function beforeSend() {
        $submit.addClass('loading');
      },
      success: function success(data) {
        $submit.removeClass('loading');
        wprs_show_message($message, data.data, data.success);
        if ($callback) {
          $callback(data);
        }
        return false;
      },
      error: function error(data) {
        $submit.removeClass('loading');
        wprs_show_message($message, data.data, data.success);
      }
    });
  }

  /**
   * 验证会员 ID
   */
  $body.on('blur', '#user_login', function (event, element) {
    var el = $(this);
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.validate_uid,
      dataType: 'json',
      data: {
        'user_login': $(this).closest('form').find('#user_login').val(),
        'action': $(this).closest('form').find('input[name="action"]').val()
      },
      success: function success(data) {
        wprs_show_field_message(el, data.data, data.success);
      }
    });
    return false;
  });

  /**
   * 验证手机号码
   */
  $body.on('blur', '.modal-register-form #user_phone', function (event, element) {
    var el = $(this);
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.validate_user_phone,
      dataType: 'json',
      data: {
        'user_phone': $(this).closest('form').find('#user_phone').val()
      },
      success: function success(data) {
        wprs_show_field_message(el, data.data, data.success);
      }
    });
    return false;
  });

  /**
   * 验证用户邮件
   */
  $body.on('blur', '#js-form-register #user_email', function (event, element) {
    var el = $(this);
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.validate_email,
      dataType: 'json',
      data: {
        'user_email': $(this).closest('form').find('#user_email').val()
      },
      success: function success(data) {
        wprs_show_field_message(el, data.data, data.success);
      }
    });
    return false;
  });

  /**
   * 验证密码，是否可以在前端实现？
   */
  $body.on('blur', '#js-form-register #password, #js-form-password #password', function (event, element) {
    var el = $(this);
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.validate_password,
      dataType: 'json',
      data: {
        'password': $(this).closest('form').find('#password').val()
      },
      success: function success(data) {
        wprs_show_field_message(el, data.data, data.success);
      }
    });
    return false;
  });

  /**
   * 验证确认密码
   */
  $body.on('blur', '#js-form-register #re_password, #js-form-password #re_password', function (event, element) {
    var el = $(this);
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.validate_re_password,
      dataType: 'json',
      data: {
        'password': $(this).closest('form').find('#password').val(),
        're_password': $(this).closest('form').find('#re_password').val()
      },
      success: function success(data) {
        wprs_show_field_message(el, data.data, data.success);
      }
    });
    return false;
  });

  /**
   * 获取验证码
   * @param event
   * @returns {boolean}
   */
  function getPhoneCode(event) {
    event.preventDefault();
    var self = $(event.target);
    var status_el = $(this).closest('form').find('.js-security-status');

    // 向后台发送处理数据
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.get_phone_code,
      dataType: 'json',
      data: {
        'user_phone': $(this).closest('form').find('input[name=user_phone]').val(),
        'action': $(this).closest('form').find('input[name=action]').val()
      },
      success: function success(data) {
        if (data.success === false) {
          status_el.removeClass('rsc-alert--success').addClass('rsc-alert rsc-alert--danger').html(data.data);
        } else {
          // 验证码发送成功后，启动计时器
          curCount = count;

          // 设置button效果，开始计时
          self.val(curCount + '秒后重新获取');
          InterValObj = window.setInterval(wprs_set_timer, 1000); //启动计时器，1秒执行一次
          wprs_show_message(status_el, data.data, data.success);
        }
      }
    });
    return false;
  }

  /**
   * 获取电子邮件验证码
   *
   * @param event
   * @returns {boolean}
   */
  function getEmailCode(event) {
    event.preventDefault();
    var self = $(event.target);
    var status_el = $(this).closest('form').find('.js-security-status');

    // 向后台发送处理数据
    $.ajax({
      method: 'POST',
      url: WenpriseSecurity.url.get_email_code,
      dataType: 'json',
      data: {
        'email': $(this).closest('form').find('input[name=user_email]').val(),
        'action': $(this).closest('form').find('input[name=action]').val()
      },
      success: function success(data) {
        if (data.success === false) {
          status_el.removeClass('rsc-alert--success').addClass('rsc-alert rsc-alert--danger').html(data.data);
        } else {
          // 验证码发送成功后，启动计时器
          curCount = count;

          // 设置button效果，开始计时
          self.val(curCount + '秒后重新获取');
          InterValObj = window.setInterval(wprs_set_timer, 1000); //启动计时器，1秒执行一次
          wprs_show_message(status_el, data.data, data.success);
        }
      }
    });
    return false;
  }

  /**
   * 发送短信验证码
   * @returns {boolean}
   */
  $body.on('click', '#js-get-code', {}, getPhoneCode);
  $body.on('click', '#js-get-email-code', {}, getEmailCode);
  $body.on('click', '#js-get-login-code', {}, getPhoneCode);
  $('#js-form-register').validator({
    isErrorOnParent: true,
    klass: 'rsc-input--danger'
  });

  /**
   * 用户登录
   */
  $body.on('submit', '#js-form-login', function (e) {
    e.preventDefault();
    wprs_ajax_submit($(this), WenpriseSecurity.url.login, function (data) {
      if (data.success === true) {
        setTimeout(function () {
          if (WenpriseSecurity.url.redirect_url !== '') {
            window.location.href = WenpriseSecurity.url.redirect_url;
          } else {
            window.location.reload();
          }
          $('#js-login-submit').removeClass('loading');
        }, 1000);
      }
    });
  });

  /**
   * 用户注册
   */
  $body.on('submit', '#js-form-register', function (e) {
    e.preventDefault();
    wprs_ajax_submit($(this), WenpriseSecurity.url.register, function (data) {
      if (data.success === true) {
        setTimeout(function () {
          if (WenpriseSecurity.url.redirect_url !== '') {
            window.location.href = WenpriseSecurity.url.redirect_url;
          } else {
            window.location.reload();
          }
          $('#js-login-submit').removeClass('loading');
        }, 3000);
      } else {
        wprs_refresh_captcha_code(document.getElementById('js-get-captcha'));
      }
    });
  });

  /**
   * 设置用户名密码
   */
  $body.on('submit', '#js-form-update', function (e) {
    e.preventDefault();
    wprs_ajax_submit($(this), WenpriseSecurity.url.update_profile, function (data) {
      if (data.success === true) {
        window.location.href = WenpriseSecurity.url.account_url !== '' ? WenpriseSecurity.url.account_url : WenpriseSecurity.url.home_url;
      } else {
        wprs_show_message($('#js-form-update .js-security-status'), data.data, data.success);
      }
    });
  });

  /**
   * 社交登录绑定现有用户
   */
  $body.on('submit', '#js-form-account-bind', function (e) {
    e.preventDefault();
    wprs_ajax_submit($(this), WenpriseSecurity.url.account_bind_url, function (data) {
      if (data.success === true) {
        window.location.href = WenpriseSecurity.url.account_url !== '' ? WenpriseSecurity.url.account_url : WenpriseSecurity.url.home_url;
      } else {
        wprs_show_message($('#js-form-update .js-security-status'), data.data, data.success);
      }
    });
  });

  /**
   * 解绑手机
   */
  $body.on('submit', '#js-form-phone', function (e) {
    e.preventDefault();
    wprs_ajax_submit($(this), WenpriseSecurity.url.phone, function (data) {
      if (data.success === true) {
        setTimeout(function () {
          if (WenpriseSecurity.url.redirect_url !== '') {
            window.location.href = WenpriseSecurity.url.redirect_url;
          } else {
            window.location.reload();
          }
          $('#js-login-submit').removeClass('loading');
        }, 1000);
      }
    });
  });

  /**
   * 找回密码
   */
  $body.on('submit', '#js-form-password', function (e) {
    e.preventDefault();
    wprs_ajax_submit($(this), WenpriseSecurity.url.forgot_password);
  });

  /**
   * Modal 默认设置
   */
  $.jqmodal.defaults = {
    closeExisting: true,
    blockerClass: 'rsc-blocker',
    modalClass: 'rsc-modal',
    closeClass: 'rsc-modal__close',
    closeText: '!',
    escapeClose: true,
    clickClose: true,
    spinnerHtml: '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div>',
    showSpinner: true,
    showClose: false,
    fadeDuration: null,
    fadeDelay: 1.0
  };

  // Automatically bind links with rel="modal--close" to, well, close the modal.
  $(document).on('click.jqmodal', 'a[rel~="modal--close"], a[href~="#js-modal-close"]', $.jqmodal.close);
  $(document).on('click.jqmodal', 'a[href~="#js-modal-login"]', function (event) {
    event.preventDefault();
    $('#js-modal-login').jqmodal();
  });
  $(document).on('click.jqmodal', 'a[href~="#js-modal-register"]', function (event) {
    event.preventDefault();
    $('#js-modal-register').jqmodal();
  });
  $(document).on('click.jqmodal', 'a[href~="#js-modal-password"]', function (event) {
    event.preventDefault();
    $('#js-modal-password').jqmodal();
  });
  $(document).on('click.jqmodal', 'a[href~="#js-modal-phone"]', function (event) {
    event.preventDefault();
    $('#js-modal-phone').jqmodal();
  });
  $(document).on('click', '.rsc-password-visible', function (event) {
    $(this).siblings('input[name=user_password], input[name=password]').attr('type', 'text');
    $(this).hide();
    $(this).siblings('.rsc-password-invisible').css('display', 'inline-flex');
  });
  $(document).on('click', '.rsc-password-invisible', function (event) {
    $(this).siblings('input[name=user_password], input[name=password]').attr('type', 'password');
    $(this).hide();
    $(this).siblings('.rsc-password-visible').css('display', 'inline-flex');
  });
});